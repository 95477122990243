<template>
  <div>
    <page-loading :loading="initializing" />

    <div v-if="!initializing">
      <v-card class="mb-4">
        <v-card-title>
          <v-icon left> mdi-message </v-icon>
          <span class="font-weight-bold">{{ $t('tickets.ticketInfo') }}</span>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col sm="6">
              <c-label :label="$t('tickets.senderUser')" class="d-block"> {{ model.sender.fullName }}</c-label>
            </v-col>

            <v-col sm="6">
              <c-label :label="$t('tickets.receiverUser')" class="d-block"> {{ model.receiver.fullName }}</c-label>
            </v-col>

            <v-col cols="12">
              <c-label :label="$t('tickets.subject')" class="d-block"> {{ model.subject }}</c-label>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="pa-4">
        <div v-for="message in model.messages" :key="message.id" class="mb-4">
          <MessageBox :value="message" class="col col-sm-9 pa-0" :class="{'ms-auto': !message.sended}" />
        </div>

        <MessageCreator :ticketId="model.id" @refresh="getModel">
          <template #activator="{attrs, on}">
            <v-btn color="success" v-bind="attrs" v-on="on">
              {{ $t('tickets.sendMessage') }}
            </v-btn>
          </template>
        </MessageCreator>

        <template #actions>
          <page-footer hideEdit :loading="progressing" />
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import EditForm from './EditForm'
import MessageBox from './components/MessageBox'
import MessageCreator from './components/MessageCreator.vue'
export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  components: {MessageBox, MessageCreator},
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.ticketsIdn.getById(this.id).then((res) => {
        this.model = this.createModelIn(res)
        this.$setBreadcrumbs([
          {text: this.$t('nav.tickets'), to: '/org/tickets'},
          {text: res.subject, disabled: true}
        ])
      })
    },
    createModelIn(model) {
      const userInfoMapper = {
        [model.receiverUserId]: model.receiver,
        [model.senderUserId]: model.sender
      }
      model.messages = model.messages.map((message) => {
        return {
          ...message,
          userInfo: userInfoMapper[message.userId],
          sended: message.userId === this.$store.getters.userId
        }
      })
      return model
    }
  }
}
</script>
