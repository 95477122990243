<template>
  <v-dialog v-model="open" transition="dialog-bottom-transition" max-width="600" persistent>
    <template v-slot:activator="props">
      <slot name="activator" v-bind="props">
        <v-btn small color="success" v-bind="props.attrs" v-on="props.on">
          <v-icon> mdi-account </v-icon>
        </v-btn>
      </slot>
    </template>

    <ValidationObserver v-slot="{handleSubmit}">
      <v-card>
        <v-toolbar color="primary" dark>{{ $t('tickets.sendMessage') }} </v-toolbar>
        <v-card-text class="pt-4">
          <c-input v-model="model.body" textarea rules="required" :label="$t('tickets.text')" />

          <uploader v-model="model.attachments" multiple />
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn color="success" @click="handleSubmit(submit)"> {{ $t('tickets.send') }}</v-btn>
          <v-btn @click="close" color="error"> {{ $t('shared.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
export default {
  name: 'TicketMessageCreator',
  props: {
    ticketId: {type: [Number, String], default: null}
  },
  data() {
    return {
      open: false,
      model: {
        attachments: null,
        body: null
      }
    }
  },
  methods: {
    close() {
      this.open = false
    },
    async submit() {
      try {
        await this.$api.ticketMessages.add(this.ticketId, this.model)
        this.$showSuccess(this.$t('tickets.messageSended'))
        this.close()
        this.$emit('refresh')
      } catch (err) {
        this.$showError(err)
      }
    }
  }
}
</script>
